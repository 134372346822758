.AppOpenSans {
   font-family: Oxygen;
}
.TopLinkText {
   margin: 0px 10px;
}
.TopLinkText:hover {
   text-decoration: underline;
   cursor: pointer;
}

.ContactButton {
   background-color: black;
   width: 8em;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 2px;
   color: white;
   cursor: pointer;
   text-decoration: none;
}

.ContactButton:hover {
   border: 1px solid #54007a;
   background-color: white;
   margin-right: 0px;
   color: #54007a;
}

.HeroSectionImage {
   height: auto;
   width: 30vw;
}

.HeroSectionImage:hover {
   width: 31vw;
}

.HowWorksImage {
   height: auto;
   width: 60vw;
   border-radius: 0.9vw;
   box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
   z-index: 1;
}

.orb1 {
   height: 10vw;
   width: 10vw;
   position: absolute;
   left: 8vw;
   border-radius: 10vw;
   background: linear-gradient(180deg, #f6f4f6 0%, #e3d8ed 100%);
   z-index: 1;
}

.orb2 {
   height: 6vw;
   width: 6vw;
   position: absolute;
   left: 78vw;
   top: 130vh;
   border-radius: 10vw;
   background: linear-gradient(180deg, #f6f4f6 0%, #e3d8ed 100%);
   z-index: 0;
}

.orb3 {
   height: 12vw;
   width: 12vw;
   position: absolute;
   left: 81vw;
   top: 160vh;
   border-radius: 10vw;
   background: linear-gradient(180deg, #f6f4f6 0%, #e3d8ed 100%);
   z-index: 1;
}

.floating {
   animation-name: floating;
   animation-duration: 3s;
   animation-iteration-count: infinite;
   animation-timing-function: ease-in-out;
}

@keyframes floating {
   0% {
      transform: translate(0, 0px);
   }
   50% {
      transform: translate(0, -30px);
   }
   100% {
      transform: translate(0, -0px);
   }
}

.fadein {
   animation: fadeIn 2s;
}

@keyframes fadeIn {
   0% {
      opacity: 0;
   }
   100% {
      opacity: 1;
   }
}

.featureBox {
   /* background-color: blue; */
   height: 30vh;
   width: 30vw;
   margin: 2vw;
   border-radius: 2vw;
   display: flex;
   align-items: center;
   border: 1px solid #000000;
}

.featureBox:hover {
   background-color: white;
}

.landing-container {
   width: 100%;
   max-width: 1200px;
   margin: 0 auto;
}

.hero-section {
   background-color: #2d0a3e;
   color: white;
   padding: 80px 20px;
   text-align: center;
}

.hero-section h1 {
   font-size: 48px;
   margin-bottom: 24px;
}

.demo-button {
   background-color: #54007a;
   color: white;
   padding: 12px 24px;
   border: none;
   border-radius: 4px;
   cursor: pointer;
}

.features-grid {
   padding: 80px 20px;
   background-color: white;
}

.feature-card {
   background: white;
   padding: 24px;
   border-radius: 8px;
   box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.workflow-section {
   background-color: #f6f4f6;
   padding: 80px 20px;
}

.workflow-steps {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
   gap: 24px;
   margin-top: 40px;
}

@keyframes scroll {
   0% {
      transform: translateX(0);
   }
   100% {
      transform: translateX(-50%);
   }
}

.logo-scroll {
   display: flex;
   gap: 4em;
   width: max-content;
   animation: scroll 40s linear infinite;
}

@keyframes scroll {
   0% {
      transform: translateX(0);
   }
   100% {
      transform: translateX(-50%);
   }
}

.logo-scroll:hover {
   animation-play-state: paused;
}

/* Add more styles as needed */

html {
   scroll-padding-top: 80px; /* This should match the header height */
   scroll-behavior: smooth;
}
